<template>
    <div class="notifies">
        <NotifiesMessages />
    </div>
    <router-view></router-view>
</template>

<script>
import NotifiesMessages from "@/components/NotifiesMessages.vue"

export default {
    name: 'App',
    components: {
        NotifiesMessages
    }
}
</script>

<style>
</style>
