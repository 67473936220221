
<template>
<div class="containerlist">
    <PreloadEntities/>
    <ItemsList></ItemsList>
</div>
</template>

<script>
/* eslint-disable */
import ItemsList from "@/components/Pages/PlacementDealTab/ItemsList.vue";
import PreloadEntities from "@/components/Pages/PreloadEntities.vue";
export default {
    name: "PlacementDealTabView",
    props: ['organization'],
    components: {
        ItemsList,
        PreloadEntities,
    }
}
</script>


<style>

</style>