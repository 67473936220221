<template>
  <div v-if="createDealTab">
    <h3 class="text-center py-5">Для добавления товара в сделку, ее нужно сохранить</h3>
  </div>
  <div v-if="dealId" class="items-list py-2">

    <div class="my-3" @click="consolelog()">
      Тип контрагента: <b>{{ ndsInPrice ? 'Юр. лицо' : 'Физ. лицо' }}</b>
    </div>

    <button class="btn btn-sm btn-outline-success mb-2" @click="addItem()">Добавить товарную позицию</button>

    <!--        <div class="my-3">-->
    <!--            <label>-->
    <!--                <input type="checkbox" class="form-check-inline" v-model="ndsInPrice"> НДС включен в стоимость-->
    <!--            </label>-->
    <!--        </div>-->

    <!--    <button class=" mb-2" @click="test()">TEST</button>-->


    <div class="table-scroll">
      <div class="table-scroll-body">
        <div class="table-scroll-content">
          <table class="table table-bordered" style="background-color: white;">
            <thead>
            <tr>
              <th></th>
              <th style="min-width: 650px;">ТМЦ</th>
              <th style="min-width: 77px;">Кол-во</th>
              <th style="min-width: 177px;">Категория цен</th>
              <th style="width: 77px;">Отгружено</th>
              <th style="min-width: 77px;">Возврат</th>
              <th style="min-width: 97px;">Произведено / Куплено</th>
              <th style="min-width: 97px;">Готово (под счет и св-дно)</th>
              <th style="min-width: 97px;">Доступно</th>
              <th style="min-width: 97px;text-align: center">Экземпляр</th>
              <th style="min-width: 115px;" v-if="ndsInPrice">Ставка НДС</th>
              <th style="min-width: 100px;">Скидка %</th>
              <th style="min-width: 177px;">Акция</th>
              <th style="min-width: 123px;">Цена прайса</th>
              <th style="min-width: 123px;">Цена без скидки и наценки</th>
              <th style="min-width: 123px;">Итоговая цена</th>
              <th style="min-width: 123px;" v-if="ndsInPrice">Сумма НДС</th>
              <th style="min-width: 123px;" v-if="ndsInPrice">Сумма без НДС</th>
              <th style="min-width: 151px;">Сумма без скидки</th>
              <th style="min-width: 151px;">Сумма со скидкой</th>
            </tr>
            </thead>

            <draggable class="draggable-list" :tag="'tbody'" ghost-class="ghost" @start="dragging = true"
                       @end="dragging = false" :disabled="!enabled"
                       :itemKey="'id'" :list="items" group="kanban">
              <template #item="{ element }">
                <tr :class="{ 'not-draggable': !enabled }">
                  <td>
                    <button class="btn btn-outline-danger"
                            @click="markToDeleteItem(getIndex(element))">
                      <i class="bi bi-x-lg"></i>
                    </button>
                  </td>

                  <td style="min-width: 250px;">
                    <div class="d-flex align-items-center justify-content-start">
                      <div class="description-product">
                        <div v-if="element.crm"
                             @mouseover="getTurboRemains(element.searchProdId ? element.searchProdId : element.id_product.value)"
                             class="image-product d-flex align-items-center justify-content-center">
                          <template
                              v-if="element.crm.PREVIEW_PICTURE && element.crm.PREVIEW_PICTURE.showUrl">
                            <img :src="'https://crm.restoracia.ru'+element.crm.PREVIEW_PICTURE.showUrl"
                                 alt="img"
                                 style="width:100%; max-width: 50px;max-height: 50px;">
                          </template>
                          <template v-else>
                            <div style="width: 50px;height: 50px; background: gray;"></div>
                          </template>
                        </div>

                        <div class="description-product-val" v-if="element.crm">
                          <template
                              v-if="element.crm.PREVIEW_PICTURE && element.crm.PREVIEW_PICTURE.showUrl">
                            <img :src="'https://crm.restoracia.ru'+element.crm.PREVIEW_PICTURE.showUrl"
                                 alt="img"
                                 style="width:100%; max-width: 100px;max-height: 100px;">
                          </template>
                          <p>
                            <a style="color: black;"
                               :href="'https://crm.restoracia.ru/crm/catalog/14/product/'+element.crm.ID+'/'"
                               target="_blank">{{ element.crm.NAME }}</a>
                          </p>
                          <div v-if="element.turboRemains === undefined">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                          <div class="table-remains" v-else>
                            <table class="table table-bordered"
                                   v-if="element.turboRemains && element.turboRemains.Divisions">
                              <thead>
                              <tr>
                                <th v-for="(remain, idx) in element.turboRemains.Divisions"
                                    :key="idx">
                                  {{ remain.Division }}
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td v-for="(remain, idx) in element.turboRemains.Divisions"
                                    :key="idx">
                                  {{ remain.Quantity }}
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <button class="btn btn-sm btn-outline-warning"
                                    @click="openRemainsPopup(getIndex(element))">
                              Подробно
                            </button>
                          </div>
                          <div>
                            <template
                                v-if="element.crm.PROPERTY_82 && element.crm.PROPERTY_82.value">
                              Остаток: <b>{{ element.crm.PROPERTY_82.value }}</b> <br>
                            </template>
                            <template
                                v-if="element.crm.PROPERTY_83 && element.crm.PROPERTY_83.value">
                              Доступно: <b>{{ element.crm.PROPERTY_83.value }}</b> <br>
                            </template>
                            <template
                                v-if="element.crm.PROPERTY_84 && element.crm.PROPERTY_84.value">
                              Цена актуальна на дату: <b>{{
                                element.crm.PROPERTY_84.value
                              }}</b> <br>
                            </template>
                            {{
                              element.crm.PROPERTY_72 && element.crm.PROPERTY_72.value ? element.crm.PROPERTY_72.value : '-'
                            }}
                          </div>
                        </div>
                      </div>

                      <Multiselect v-model="element.id_product" placeholder="Товар ТМЦ"
                                   noOptionsText="Для поиска введите название товара"
                                   :filter-results="false"
                                   :min-chars="2"
                                   :object="true"
                                   :resolve-on-load="false"
                                   :delay="333"
                                   :searchable="true"
                                   :clear-on-search="true"
                                   @select="changeProductInSelect(getIndex(element))"
                                   :options="async (query) => {
                                                        return await searchProduct(query)
                                                     }"
                      />
                    </div>
                  </td>

                  <td>
                    <input type="number" class="form-control" v-model="element.quantity" min="1">
                  </td>
                  <td>
                    <Multiselect v-model="element.price_cat"
                                 :options="(element.crm && element.crm.CATEGORY_PRICE) ?? []"
                                 :valueProp="'id'"
                                 @select="changeProductCategoryPrice(getIndex(element))"
                                 :trackBy="'name'" :label="'name'" :searchable="true"/>
                  </td>

                  <td>
                    <template v-if="sendTurbo">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </template>
                    <template v-else>
                      {{
                        element && element.turbo && element.turbo.Otgrugeno !== undefined ? element.turbo.Otgrugeno : '-'
                      }}
                    </template>
                  </td>
                  <td>
                    <template v-if="sendTurbo">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </template>
                    <template v-else>
                      {{
                        element && element.turbo && element.turbo.Vozvrat !== undefined ? element.turbo.Vozvrat : '-'
                      }}
                    </template>
                  </td>
                  <td>
                    <template v-if="sendTurbo">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </template>
                    <template v-else>
                      {{
                        element && element.turbo && element.turbo.Proizv !== undefined ? element.turbo.Proizv : '-'
                      }}
                    </template>
                  </td>
                  <td>
                    <template v-if="sendTurbo">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </template>
                    <template v-else>
                      {{
                        element && element.turbo && element.turbo.Gotovo !== undefined ? element.turbo.Gotovo : '-'
                      }}
                    </template>
                  </td>
                  <td>
                    <template v-if="sendTurbo">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </template>
                    <template v-else>
                      {{
                        element && element.turbo && element.turbo.Dostupno !== undefined ? element.turbo.Dostupno : '-'
                      }}
                    </template>
                  </td>

                  <td class="d-flex align-items-center justify-content-center">
                    <button v-if="!element.exemplar.alter_name"
                            class="btn btn-outline-secondary btn-sm"
                            @click="openElementCreateModal(getIndex(element))">
                      создать
                    </button>
                    <button class="btn btn-link" v-else
                            @click="openElementCreateModal(getIndex(element))"
                            style="min-width: 300px;">
                      {{ element.exemplar.alter_name }}
                    </button>
                  </td>
                  <td v-if="ndsInPrice">
                    {{ ndsInPrice ? "НДС 20%" : "Без НДС" }}
                  </td>
                  <td>
                    <input type="number" class="form-control" v-model="element.sale" min="0"
                           max="100"
                           @change="validateSale($event, getIndex(element))">
                  </td>
                  <!--                                    <td>-->
                  <!--                                        <Multiselect v-model="element.sale_id" :options="$store.state.crmProductSales"-->
                  <!--                                                     :valueProp="'ID'"-->
                  <!--                                                     @select="changeProductSale(getIndex(element))" :trackBy="'NAME'"-->
                  <!--                                                     :label="'NAME'"-->
                  <!--                                                     :searchable="true"/>-->
                  <!--                                    </td>-->


                  <td style="min-width: 250px;">
                    <Multiselect v-model="element.sale_id" placeholder="Акция"
                                 noOptionsText="Для поиска введите название акции"
                                 :label="'label'"
                                 :trackBy="'label'"
                                 :filter-results="false"
                                 :min-chars="0"
                                 :object="true"
                                 :resolve-on-load="true"
                                 :delay="0"
                                 :searchable="true"
                                 @select="changeSaleInSelect(getIndex(element))"
                                 :options=" async (query) => {return await searchSales(query)}"
                    />
                  </td>
                  <td>
                    <template v-if="element.crm && element.crm.PRICE">
                      {{
                        element.crm.PRICE.toLocaleString('ru-RU', {
                          style: 'currency',
                          currency: 'RUB'
                        })
                      }}
                    </template>
                  </td>
                  <td>
                    <input type="number" class="form-control" v-model="element.price"
                           @change="validatePrice($event, getIndex(element))">
                  </td>
                  <td>
                    {{
                      (calcSale(element.price, element.sale)).toLocaleString('ru-RU', {
                        style: 'currency',
                        currency: 'RUB'
                      })
                    }}
                  </td>
                  <td v-if="ndsInPrice">
                    {{
                      (calcSale(element.price * element.quantity, element.sale) - calcNoNDS(calcSale(element.price * element.quantity, element.sale), ndsPercent)).toLocaleString('ru-RU', {
                        style: 'currency',
                        currency: 'RUB'
                      })
                    }}
                  </td>
                  <td v-if="ndsInPrice">
                    {{
                      calcNoNDS(calcSale(element.price * element.quantity, element.sale), ndsPercent).toLocaleString('ru-RU', {
                        style: 'currency',
                        currency: 'RUB'
                      })
                    }}
                  </td>
                  <td>
                    {{
                      (element.price * element.quantity).toLocaleString('ru-RU', {
                        style: 'currency',
                        currency: 'RUB'
                      })
                    }}
                  </td>
                  <td>
                    {{
                      (calcSale(element.price * element.quantity, element.sale)).toLocaleString('ru-RU', {
                        style: 'currency',
                        currency: 'RUB'
                      })
                    }}
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>
      </div>
      <hr>
      <p v-if="ndsInPrice" class="descr-price">
        В том числе НДС:
        <b>
          {{
            allSumNds.toLocaleString('ru-RU', {
              style: 'currency',
              currency: 'RUB'
            })
          }}
        </b>
      </p>
      <p class="descr-price">
        Всего к оплате:
        <b>
          {{
            allSumWithNds.toLocaleString('ru-RU', {
              style: 'currency',
              currency: 'RUB'
            })
          }}
        </b>
      </p>
      <button class="btn btn-sm btn-success" :disabled="sending" @click="saveProductsToDeal()">
        <div v-if="sending" class="spinner-border  spinner-border-sm mr-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        Сохранить
      </button>
    </div>


    <div v-if="openModal">
      <modal-window ref="confirmationPopup" :item="items[currentExemplarIndex]"
                    :crm="items[currentExemplarIndex].crm"
                    @deleteWindow="deleteWindow(currentExemplarIndex)"
                    @closeWindow="closeWindow()" @confirmWindow="confirmWindow($event,currentExemplarIndex)"
                    @deleteCharacteristic="deleteCharacteristic($event)"
      />
    </div>
    <div v-if="openPopupRemains !== false">
      <remains-popup :item="items[openPopupRemains]"
                     @closeWindow="openPopupRemains = false"
      />
    </div>


  </div>

</template>
<style src="@vueform/multiselect/themes/default.css"></style>
<script>
/* eslint-disable */
import Multiselect from '@vueform/multiselect'
import ModalWindow from './CreateElementModal'
import RemainsPopup from './RemainsPopup'
import Datepicker from '@vuepic/vue-datepicker';
import draggable from "vuedraggable";

// import '@vuepic/vue-datepicker/dist/main.css'
export default {
  components: {
    draggable,
    Multiselect,
    ModalWindow,
    RemainsPopup,
    Datepicker,
  },
  computed: {
    ndsInPrice: function () {
      return this.$store.state.currentDeal && this.$store.state.currentDeal.UF_CRM_1703488156 && this.$store.state.currentDeal.UF_CRM_1703488156 === '1743' // true - юрик     false - физ
    },
    allSumNds: function () {
      return this.allSumWithNds * this.ndsPercent / (this.ndsPercent + 100);
    },
    allSumWithNds: function () {
      let sum = 0;
      for (let item of this.items) {
        // sum += this.ndsInPrice ? this.calcSale(item.price * item.quantity, item.sale) : this.calcSale((this.calcNoNDS(item.price, this.ndsPercent) * item.quantity), item.sale)
        sum += this.calcSale(item.price * item.quantity, item.sale)
      }
      return sum;
    }
  },
  methods: {
    getIndex(element) {
      return this.items.indexOf(element)
    },
    test() {
      let item = this.getObjValue(this.items[0]);
      for (let i = 0; i < 100; i++) {
        item.id = '';
        this.items.push(this.getObjValue(item));
      }
    },

    consolelog: function () {
      console.log(this.$store.state.currentDeal)
    },
    validateSale: function (event, index) {
      console.log(event, event.target, index);
      let prod = this.items && this.items[index] ? this.items[index] : null;
      if (prod && prod.crm && event.target.value * 1) {
        let arr = [...this.items];
        console.log('this.currentUser', this.currentUser)
        if (this.currentUser && this.currentUser.WORK_POSITION && this.currentUser.WORK_POSITION.toLowerCase() === 'руководитель отдела продаж') {
          arr[index].sale = event.target.value * 1;
        } else {
          if (event.target.value * 1 > 5 && this.allSumWithNds < 120000) {
            arr[index].sale = 5;
          }
          if (event.target.value * 1 > 5 && this.allSumWithNds >= 120000 && this.allSumWithNds <= 200000) {
            arr[index].sale = 5;
          }
          if (event.target.value * 1 > 7 && this.allSumWithNds > 200000 && this.allSumWithNds <= 300000) {
            arr[index].sale = 7;
          }
          if (event.target.value * 1 > 8 && this.allSumWithNds > 300000 && this.allSumWithNds <= 350000) {
            arr[index].sale = 8;
          }
          if (event.target.value * 1 > 10 && this.allSumWithNds > 350000 && this.allSumWithNds <= 1000000) {
            arr[index].sale = 10;
          }
          if (event.target.value * 1 > 15 && this.allSumWithNds > 1000000) {
            arr[index].sale = 15;
          }
        }

        this.items = arr;
      }
    },
    validatePrice: function (event, index) {
      let prod = this.items && this.items[index] ? this.items[index] : null;
      if (prod && prod.crm && prod.min_price && event.target.value * 1 < prod.min_price * 1) {
        let arr = [...this.items];
        arr[index].price = prod.min_price;
        this.items = arr;
      }
    },
    calcNoNDS: function (price, nds) {
      return price - (price * nds / (100 + nds))
    },
    calcSale: function (price, sale) {
      return price - price * (sale / 100)
    },
    addItem: function () {
      JSON.parse(JSON.stringify(this.item_template));
      this.items.unshift(this.getObjValue(this.item_template));//{...очему-то не работает - берет ссылку, а не значение, везде поменял пока на
    },

    getObjValue(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    changeProductSale: function (index) {
      this.$nextTick(() => {
        // выбрали новую акцию в селект инпуте
        let prod = this.items && this.items[index] ? this.items[index] : null;
        // вот тут достали нужную нам позицию товара, в котором выбрали новую категорию
        if (prod && prod.sale_id) {
          // находим по ид категорию которую выбрали в селекте
          let sale = this.$store.state.crmProductSales.find(item => item.ID === prod.sale_id);
          // теперь если нашлась, нужно применить новую цену
          if (sale) {
            // нашлось и перезаписали
            let arr = [...this.items]
            arr[index].sale = sale.PROPERTY_VALUES && sale.PROPERTY_VALUES.sale ? sale.PROPERTY_VALUES.sale * 1 : 0
            this.items = arr;
          }
        }
      });
    },
    changeProductCategoryPrice: function (index) {
      this.$nextTick(() => {
        // выбрали новую категорию цен в селект инпуте
        let prod = this.items && this.items[index] ? this.items[index] : null;
        // вот тут достали нужную нам позицию товара, в котором выбрали новую категорию
        if (prod && prod.crm && prod.price_cat) {
          // находим по ид категорию которую выбрали в селекте
          let category = prod.crm.CATEGORY_PRICE.find(item => item.id === prod.price_cat);
          // теперь если нашлась, нужно применить новую цену
          if (category) {
            // нашлось и перезаписали
            let arr = [...this.items]
            arr[index].price = category.price * 1
            this.items = arr;
          }
        }
      });
    },

    markToDeleteItem: function (index) {
      this.deleteExemplars(index)//Добавил
      let prod = this.items && this.items[index] ? this.items[index] : null;
      if (prod && prod.id) {
        this.itemsForDelete.push(this.getObjValue(prod));
      }
      this.items.splice(index, 1)
    },

    deleteExemplars(index) {
      this.deleteCharacteristics(index)
      let exemplar = this.items && this.items[index] && this.items[index].exemplar ? this.items[index].exemplar : null;
      if (exemplar && exemplar.id) {
        this.exemplarForDelete.push(exemplar.id);
      }
      this.items[index].exemplar = {};
      this.items[index].id_exemplar = '';

    },

    deleteCharacteristics(index) {
      let characteristics = this.items && this.items[index] && this.items[index].exemplar && this.items[index].exemplar.items ? this.items[index].exemplar.items : null;
      if (characteristics) {
        for (let characteristic of characteristics) {
          if (characteristic && characteristic.id) {
            this.characteristicForDelete.push(characteristic.id);
          }
        }
      }

      this.items[index].exemplar.items = [];
    },

    searchProdInCrmAndPasteToItems: async function (index, changePrice = true) {
      let prod = this.items && this.items[index] ? this.items[index] : null;
      // достали  наш продукт в массиве, который выбрали
      if (prod && ((prod.id_product && prod.id_product.value) || prod.searchProdId)) {
        // сразу достаем выбраный нами товар со всеми свойствами из битрикса

        return await new Promise((resolve) => {
          BX24.callMethod(
              "crm.product.get",
              {
                'id': prod.searchProdId ? prod.searchProdId : prod.id_product.value
              },
              (result) => {
                if (result.error()) {
                  alert('Ошибка поиска товаров');
                  console.error('Ошибка поиска товаров', result.error());
                } else {
                  console.log('PRODUCT SELECT', result.data())
                  let arr = [...this.items]
                  let tmp = result.data()
                  // акции соберем в массив, для того, чтоб потом выводить его на морде как селект
                  if (tmp.PROPERTY_79 && tmp.PROPERTY_80 && tmp.PROPERTY_81) {
                    tmp.CATEGORY_PRICE = [
                      {
                        id: 'PROPERTY_79',
                        price: tmp.PROPERTY_79.value,
                        name: 'Премиум цена ' + tmp.PROPERTY_79.value,
                      },
                      {
                        id: 'PROPERTY_80',
                        price: tmp.PROPERTY_80.value,
                        name: 'Стандарт ' + tmp.PROPERTY_80.value,
                      },
                      {
                        id: 'PROPERTY_81',
                        price: tmp.PROPERTY_81.value,
                        name: 'Эконом ' + tmp.PROPERTY_81.value,
                      }
                    ];
                  } else {
                    tmp.CATEGORY_PRICE = []
                  }


                  arr[index].crm = tmp
                  if (changePrice)
                    arr[index].price = tmp.PRICE * 1

                  if (prod.searchProdId) {
                    // вот сюда если попадаем, то значит что этот метод вызвался с mounted
                    // это костыль с этим свойством searchProdId так как вуе не видит изменение этого обьекта внутри массива
                    arr[index].id_product = {
                      label: tmp.NAME,
                      value: tmp.ID,
                    }
                  }

                  for (let cat of tmp.CATEGORY_PRICE) {
                    // сразу применим какую то категорию цены
                    // которая совпадает с ценой
                    if (cat.price * 1 === arr[index].price * 1) {
                      arr[index].price_cat = cat.id
                      if (changePrice)
                        arr[index].price = cat.price * 1
                    }
                  }


                  this.items = arr;
                  resolve();
                }
              }
          );
        })

      }
    },
    changeProductInSelect: function (index) {
      // в инпуте поиска товаров, выбрали товар по поиску и сработа эта функция на онселект
      this.$nextTick(() => {
        this.searchProdInCrmAndPasteToItems(index);
      });
    },


    changeSaleInSelect: function (index) {

      // if(this?.items[index]?.sale_id?.value){
      //   this.items[index].sale_id = this.items[index].sale_id.value
      // }
      // this.items[index].sale_id = items
      console.log('changeSaleInSelect', this.items)
    },


    searchProduct: async function (query) {
      let _this = this;
      let bx24response = new Promise((resolve, reject) => {
        let filter = {"CATALOG_ID": 0};

        if (_this.currentUser && Array.isArray(_this.currentUser.UF_DEPARTMENT)) {
          let isRHome = _this.currentUser.UF_DEPARTMENT.find(dep => dep * 1 === 43)
          if (isRHome)
            filter["PROPERTY_164"] = 1;
          let isRestoracia = _this.currentUser.UF_DEPARTMENT.find(dep => dep * 1 === 42)
          if (isRestoracia)
            filter["PROPERTY_164"] = 0;
          if (isRHome && isRestoracia)
            delete filter["PROPERTY_164"]
        }

        filter["%NAME"] = query.replace(/\*/g, "");
        //
        // if(query.indexOf('*')+1){
        //     filter["%NAME"] = query.replace(/\*/g,"");
        // }else{
        //     filter["%NAME"] = query;
        // }

        BX24.callMethod(
            "crm.product.list",
            {
              'order': {"NAME": "ASC"},
              'filter': filter,
              'select': ['ID', 'NAME', 'PRICE']
            },
            (result) => {
              if (result.error()) {
                alert('Ошибка поиска товаров');
                console.error('Ошибка поиска товаров', result.error());
              } else {
                resolve(result.data())
              }
            }
        );
      })
      let products = [];
      await bx24response.then(result => {
        products = result;
      });
      if (query.indexOf('*') + 1) {
        query = query.replace(/\*/g, "");
        products = products.filter(item => {
          const regex = new RegExp(`^${query}`);
          return regex.exec(item.NAME) !== null
        })
      }
      return products.map(item => {
        return {
          label: item.NAME + "  (" + ((item.PRICE * 1).toLocaleString('ru-RU', {
            style: 'currency',
            currency: 'RUB'
          })) + ")",
          value: item.ID,
        }
      });
    },


    searchSales: async function (query) {


      if (!query) {
        return this.$store.state.crmProductSales.map(item => {
          return {
            label: item.NAME,
            value: item.ID,
          }
        });
      }

      let bx24response = new Promise((resolve, reject) => {
        let today = (new Date()).toISOString().split('T')[0]
        let filter = {
          "%NAME": query.replace(/\*/g, ""),
          "0": {
            "logic": "OR",
            "0": {
              '<=PROPERTY_date_active_from': today
            },
            "1": {
              '>=PROPERTY_date_active_to': today
            }
          }

        };
        let sales = [];
        BX24.callMethod("mindentity.item.get",
            {
              ENTITY: "product_sales",
              FILTER: filter,
            },
            (result) => {
              if (result.error()) {
                console.log('crm.productsection.list', result.error());
                alert("Ошибка в консоле");
              } else {
                sales = [...sales, ...result.data()];
                if (result.more())
                  result.next();
                else
                  resolve(sales);
              }
            }
        );
      });


      let sales = [];
      await bx24response.then(result => {
        sales = result;
      });

      if (query.indexOf('*') + 1) {
        query = query.replace(/\*/g, "");
        sales = sales.filter(item => {
          const regex = new RegExp(`^${query}`);
          return regex.exec(item.NAME) !== null
        })
      }
      return sales.map(item => {
        return {
          label: item.NAME,
          value: item.ID,
        }
      });
    },


    saveProductsToDeal: function () {
      this.sending = true;
      this.$nextTick(async () => {
        let rows = [];
        let rowsEntities = [];

        let sort = 0;
        for (let item of this.items) {
          rows.push({
            "PRODUCT_ID": item.crm.ID,
            "PRICE": this.calcSale(item.price, item.sale),
            "PRICE_BRUTTO": item.price,
            "PRICE_NETTO": item.price,
            "PRICE_ACCOUNT": this.calcSale(item.price, item.sale),
            "PRICE_EXCLUSIVE": this.calcSale(item.price, item.sale),
            "QUANTITY": item.quantity,
            "DISCOUNT_RATE": item.sale,
            "DISCOUNT_TYPE_ID": 2,
            "TAX_RATE": this.ndsPercent,
            "TAX_INCLUDED": this.ndsInPrice ? "Y" : "N",
          })

          let exemplarTemp = {}, characteristicsTemp = []

          if (item.exemplar && item.exemplar.alter_name) {
            exemplarTemp = {
              ID: item.exemplar.id ? item.exemplar.id : null,
              ENTITY: "product_exemplar",
              NAME: item.id ? item.id : null,
              PROPERTY_VALUES: this.getObjValue(item.exemplar)
            }
          }

          if (item.exemplar && item.exemplar.items) {
            for (let characteristic of item.exemplar.items) {
              let temp = {
                ID: characteristic.id ? characteristic.id : null,
                ENTITY: "product_characteristic",
                NAME: item.id_exemplar ? item.id_exemplar : null,
                PROPERTY_VALUES: this.getObjValue(characteristic),
              };

              if (temp.PROPERTY_VALUES.date_reserv)
                temp.PROPERTY_VALUES.date_reserv = this.formatDateForBitrix(characteristic.date_reserv);

              if (temp.PROPERTY_VALUES.date_reserv_out)
                temp.PROPERTY_VALUES.date_reserv_out = this.formatDateForBitrix(characteristic.date_reserv_out);

              if (temp.PROPERTY_VALUES.date_confirm)
                temp.PROPERTY_VALUES.date_confirm = this.formatDateForBitrix(characteristic.date_confirm);

              characteristicsTemp.push(temp)
            }
          }


          // товарная позиция
          rowsEntities.push({
            ID: item.id ? item.id : null,
            ENTITY: "product_items",
            NAME: this.dealId,
            SORT: sort,
            PROPERTY_VALUES: {
              id_order: this.dealId,
              id_product: item.id_product.value,
              price: item.price,
              nds: this.ndsPercent,
              nds_include: this.ndsInPrice ? 1 : 0,
              sale_id: item?.sale_id?.value ? item.sale_id.value : '',
              sale: item.sale,
              price_cat: item.price_cat,
              quantity: item.quantity,
            },
            EXEMPLAR: exemplarTemp,
            CHARACTERISTICS: characteristicsTemp
          })

          sort++;
        }

        if (rowsEntities.length) {
          BX24.callMethod(
              "mindentity.item.save",
              {'ROWS': rowsEntities},
              (result) => {
                if (result.error()) {
                  alert("Ошибка сохранения товарных позиций <<save>>");
                  console.error(result.error());
                } else {
                  result = result.data();
                  for (let i = 0; i < result.length; i++) {
                    if (result[i].ID)
                      this.items[i].id = result[i].ID;

                    if (result[i].EXEMPLAR && result[i].EXEMPLAR.ID)
                      this.items[i].id_exemplar = this.items[i].exemplar.id = result[i].EXEMPLAR.ID;

                    if (Array.isArray(result[i].CHARACTERISTICS)) {
                      for (let j = 0; j < result[i].CHARACTERISTICS.length; j++) {
                        if (result[i].CHARACTERISTICS[j].ID)
                          this.items[i].exemplar.items[j].id = result[i].CHARACTERISTICS[j].ID
                      }
                    }
                  }
                }
              }
          );
        }


        /*
        for (let item of this.items) {


            // this.items[index].price = product.PRICE_BRUTTO;
            // this.items[index].sale = product.DISCOUNT_RATE;
            // this.items[index].quantity = product.QUANTITY;


            rows.push({
                "PRODUCT_ID": item.crm.ID,
                "PRICE": this.calcSale(item.price, item.sale),
                "PRICE_BRUTTO": item.price,
                "PRICE_NETTO": item.price,
                "PRICE_ACCOUNT": this.calcSale(item.price, item.sale),
                "PRICE_EXCLUSIVE": this.calcSale(item.price, item.sale),
                "QUANTITY": item.quantity,
                "DISCOUNT_RATE": item.sale,
                "DISCOUNT_TYPE_ID": 2,
                "TAX_RATE": this.ndsPercent,
                "TAX_INCLUDED": this.ndsInPrice ? "Y" : "N",
            })



            // сохраняем товарную позицию в инфоблок
            let bx24response = new Promise((resolve, reject) => {
                let updateObj = {
                    ENTITY: "product_items",
                    NAME: this.dealId,
                    PROPERTY_VALUES: {
                        id_order: this.dealId,
                        id_product: item.id_product.value,
                        price: item.price,
                        nds: this.ndsPercent,
                        nds_include: this.ndsInPrice ? 1 : 0,
                        sale_id: item.sale_id,
                        sale: item.sale,
                        price_cat: item.price_cat,
                        quantity: item.quantity,
                    }
                };
                if (item.id) {
                    updateObj['ID'] = item.id
                }
                BX24.callMethod(
                    item.id ? "mindentity.item.update" : "mindentity.item.add",
                    updateObj,
                    (result) => {
                        if (result.error()) {
                            alert("Ошибка сохранения товарных позиций в инфоблоке");
                            console.error(result.error());
                        } else {
                            resolve(result.data())
                        }
                    }
                );
            })


            // После того как сохранили товарную позицию - сохраняем эекземпляр в инфоблок,
            await bx24response.then(async result => {
                if (!item.id) // товарную позицию только только сохранили
                    item.id = result
                console.log('SAVE RESPONSE', result)


                return await new Promise((resolve) => {
                    if (item.exemplar.alter_name) {

                        let updateObj = {
                            ENTITY: "product_exemplar",
                            NAME: item.id,
                            PROPERTY_VALUES: this.getObjValue(item.exemplar)
                        };

                        if (item.exemplar.id) {
                            updateObj['ID'] = item.exemplar.id
                        }

                        BX24.callMethod(
                            item.exemplar.id ? "mindentity.item.update" : "mindentity.item.add",
                            updateObj,
                            (result) => {
                                if (result.error()) {
                                    alert("Ошибка сохранения товарных позиций в инфоблоке <<Экземпляр>>");
                                    console.error(result.error());
                                    resolve()
                                } else {
                                    if (!item.id_exemplar) {
                                        console.log('save Exemplar', result.data())
                                        item.id_exemplar = item.exemplar.id = result.data();
                                        resolve(result.data())
                                    } else {
                                        resolve(item.id_exemplar);
                                    }

                                }
                            }
                        );
                    } else {
                        resolve();
                    }


                });

            }).then(async (exemplarId) => { // сохраняем характеристики в экземпляр
                //После создания экземпляра установим exemplar_id в продукт
                if (!item.id_exemplar && exemplarId) {
                    item.id_exemplar = item.exemplar.id = exemplarId;
                }

                if (item.exemplar.items && exemplarId) {
                    for (let characteristic of item.exemplar.items) {
                        let saveCharacteristicPromise = new Promise((resolve) => {

                            let updateObj = {
                                ENTITY: "product_characteristic",
                                NAME: exemplarId,
                                PROPERTY_VALUES: this.getObjValue(characteristic),
                            };

                            console.log('SAVE CHARACTERISTIC', this.getObjValue(characteristic));
                            if (updateObj.PROPERTY_VALUES.date_reserv) {
                                updateObj.PROPERTY_VALUES.date_reserv = this.formatDateForBitrix(characteristic.date_reserv);
                            }
                            if (updateObj.PROPERTY_VALUES.date_reserv_out) {
                                updateObj.PROPERTY_VALUES.date_reserv_out = this.formatDateForBitrix(characteristic.date_reserv_out);
                            }
                            if (updateObj.PROPERTY_VALUES.date_confirm) {
                                updateObj.PROPERTY_VALUES.date_confirm = this.formatDateForBitrix(characteristic.date_confirm);
                            }

                            if (item.exemplar.id) {
                                updateObj['ID'] = characteristic.id
                            }

                            BX24.callMethod(
                                characteristic.id ? "mindentity.item.update" : "mindentity.item.add",
                                updateObj,
                                (result) => {
                                    if (result.error()) {
                                        alert("Ошибка сохранения товарных позиций в инфоблоке <<Характеристика Экземпляра>>");
                                        console.error(result.error());
                                    } else {
                                        resolve()
                                        if (!characteristic.id) {
                                            console.log('save characteristic', result.data())
                                            characteristic.id = result.data();
                                            resolve(result.data())
                                        } else {
                                            resolve();
                                        }
                                    }
                                }
                            );

                        })

                        await saveCharacteristicPromise.then((result) => {
                            if (!characteristic.id) {
                                characteristic.id = result;
                            }
                            console.log('SAVE characteristic', result)
                        });
                    }
                }


                return new Promise((resolve) => {

                    if (!exemplarId) {
                        exemplarId = '';
                    }
                    let updateObj = {
                        ENTITY: "product_items",
                        NAME: this.dealId,
                        PROPERTY_VALUES: {
                            id_exemplar: exemplarId,
                        }
                    };
                    if (item.id) {
                        updateObj['ID'] = item.id
                    }
                    BX24.callMethod(
                        "mindentity.item.update",
                        updateObj,
                        (result) => {
                            if (result.error()) {
                                alert("Ошибка сохранения element_id товарных позиций в инфоблоке");
                                console.error(result.error());
                            } else {
                                resolve(result.data())
                            }
                        }
                    );
                })

            })
            //     .then(async () => {//После установки exemplar_id создаем характеристики
            //
            // })

        }
        */

        //Удаление

        for (let item of this.exemplarForDelete) {
          BX24.callMethod(
              "mindentity.item.delete",
              {
                ENTITY: "product_exemplar",
                ID: item
              },
              (result) => {
                if (result.error()) {
                  alert("Ошибка удаления в инфоблоке Экземпляра")
                  console.error(result.error());
                } else {
                  this.exemplarForDelete = [];
                }
              }
          );
        }

        //Удаляем продукты
        for (let item of this.itemsForDelete) {
          //TODO delete exemplar
          BX24.callMethod(
              "mindentity.item.delete",
              {
                ENTITY: "product_items",
                ID: item.id
              },
              (result) => {
                if (result.error()) {
                  alert("Ошибка удаления товаров в инфоблоке")
                  console.error(result.error());
                } else {
                  this.itemsForDelete = [];
                }
              }
          );
        }

        //Удаляем характеристики
        for (let item of this.characteristicForDelete) {
          BX24.callMethod(
              "mindentity.item.delete",
              {
                ENTITY: "product_characteristic",
                ID: item
              },
              (result) => {
                if (result.error()) {
                  alert("Ошибка удаления в инфоблоке Характеристик")
                  console.error(result.error());
                } else {
                  this.characteristicForDelete = [];
                }
              }
          );
        }


        BX24.callMethod(
            "crm.deal.productrows.set",
            {
              id: this.dealId,
              rows: rows.length ? rows : [{'PRODUCT_ID': null}]
            },
            (result) => {
              if (result.error()) {
                alert("Ошибка сохранения товаров в сделке")
                console.error(result.error());
              } else {
                this.sending = false;
              }
            }
        );

      });
    },

    getContactsAllData: function (contacts) {
      let cmd = {};
      contacts.forEach((concat, index) => {
        cmd['contact' + index] = 'crm.contact.get?id=' + concat.CONTACT_ID;
        cmd['requisite' + index] = 'crm.requisite.list?filter[ENTITY_TYPE_ID]=3&filter[ENTITY_ID]=$result[contact' + index + '][ID]';
        cmd['address' + index] = 'crm.address.list?filter[ENTITY_TYPE_ID]=8&filter[ENTITY_ID]=$result[requisite' + index + '][0][ID]';
      })
      BX24.callMethod('batch',
          {
            'halt': 0,
            'cmd': cmd
          },
          (response) => {
            response = response.data().result
            for (let i = 0; i < contacts.length; i++) {
              if (response['contact' + i]) {
                let contact = response['contact' + i];
                response['requisite' + i] && response['requisite' + i][0] ? contact.REQUISITE = response['requisite' + i][0] : contact.REQUISITE = null;
                response['address' + i] && response['address' + i][0] ? contact.ADDRESES = response['address' + i][0] : contact.ADDRESES = null;
                this.$store.commit('setCurrentContacts', contact)
              }
            }
          }
      );
    },

    getTurboInfo: async function () {
      this.sendTurbo = true;
      let response = await fetch('https://crm.restoracia.ru/turbo.php?deal_id=' + this.dealId + '&token=oua5dtoea2sg6gfq1', {mode: 'no-cors'});
      console.log('turbo init');
      try {
        this.sendTurbo = false;
        let turbo = await response.json();
        console.log('turbo', turbo);
        if (turbo && turbo.Result && Array.isArray(turbo.Result)) {
          for (let i = 0; i < this.items.length; i++) {
            let prod = this.items[i];
            let prod_crm_id = prod.searchProdId ? prod.searchProdId : prod.id_product.value;
            for (let t of turbo.Result) {
              if (t && t.IDPRODUCT && prod_crm_id * 1 === t.IDPRODUCT * 1) {
                let arr = [...this.items]
                arr[i].turbo = t;
                this.items = arr;
              }
            }
          }
        }
      } catch (e) {
        this.sendTurbo = false;
      }

    },
    getTurboRemains: async function (crm_id) {


      if (this.sendTurboRemains.indexOf(crm_id) + 1)
        return;


      this.sendTurboRemains.push(crm_id)
      console.log('getTurboRemains', crm_id);


      try {
        let response = await fetch('https://crm.restoracia.ru/turbo.php?prod_id=' + crm_id + '&token=oua5dtoea2sg6gfq1');
        let turbo = await response.json();
        console.log('getTurboRemains - response', turbo);
        if (turbo && turbo.Result) {
          let arr = [...this.items]
          let index = arr.findIndex(item => ((item.searchProdId ? item.searchProdId : item.id_product.value) === crm_id))
          if (index + 1) {
            arr[index].turboRemains = turbo.Result;
            this.items = arr;
          }
        }

      } catch (e) {
        console.error(e);
      }
    },


    openRemainsPopup(index) {
      this.openPopupRemains = index;
    },

    async openElementCreateModal(index) {

      console.log(this.items[index])
      let arr = [...this.items];
      if (!arr[index].exemplar.alter_name && arr[index].crm.NAME !== undefined)
        arr[index].exemplar.name = arr[index].exemplar.alter_name = arr[index].crm.NAME;
      this.items = arr;

      this.openModal = true;
      this.currentExemplarIndex = index;
      // this.$refs.confirmationPopup.open();
    },

    deleteWindow(index) {
      this.deleteExemplars(index);

      // let arr = [...this.items];
      //
      // if (arr[index].id_exemplar) {
      //   this.exemplarForDelete.push(arr[index].id_exemplar);
      // }
      // // else if (arr[index].exemplar.id){
      // //   this.exemplarForDelete.push(arr[index].exemplar.id);
      // // }
      //
      // arr[index].exemplar = {};
      // arr[index].id_exemplar = '';
      // this.items = arr;

      this.openModal = false;

    },
    closeWindow() {
      this.openModal = false;
    },
    confirmWindow(event, index) {
      this.items[index].exemplar = this.getObjValue(event);
      this.openModal = false;
      //
    },

    deleteCharacteristic(event) {
      this.characteristicForDelete.push(event);
    },


    getSimpleExemplarName(index) {


      let str = '';
      if (this.items && this.items[index] && this.items[index].exemplar) {
        if (this.items[index].exemplar.alter_name) {
          let position = this.items[index].exemplar.alter_name.indexOf(', нестандарт');

          if (position !== -1) {
            str = this.items[index].exemplar.alter_name.slice(0, position);
          } else {
            str = this.items[index].exemplar.alter_name
          }
        }
      }
      return str;


      // let str = '';
      // let size = {
      //   dlinna: 0,
      //   visota: 0,
      //   glubina: 0,
      //   diametr: 0,
      // };
      //
      //
      // if (this.items && this.items[index] && this.items[index].exemplar) {
      //   if (this.items[index].exemplar.alter_name) {
      //     str += this.items[index].exemplar.alter_name;
      //   }
      //   size.dlinna = this.items[index].exemplar.dlinna ? '*'+this.items[index].exemplar.dlinna : 0;
      //   size.visota = this.items[index].exemplar.visota ? '*'+this.items[index].exemplar.visota : 0;
      //   size.glubina = this.items[index].exemplar.glubina ? '*'+this.items[index].exemplar.glubina : 0;
      //   size.diametr = this.items[index].exemplar.diametr ? '*'+this.items[index].exemplar.diametr : 0;
      //
      //   const values = Object.values(size);
      //   if (!(values.every(value => value === 0))) {
      //     str += `, нестандарт(${size.dlinna}${size.visota}${size.diametr}${size.glubina})`.replace(/^\*|(\*)/, '');
      //   }
      // }
      // return str;
    },

    async searchExemplar(index) {
      return await new Promise((resolve) => {
        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_exemplar",
              FILTER: {
                ID: this.items[index].id_exemplar,
              }
            },
            (result) => {
              if (result.error()) {
                alert("Ошибка поиска товарных позиций Экземпляра")
                console.error(result.error());
                this.items[index].exemplar.id = '';
                this.items[index].id_exemplar = '';
                resolve(this.items[index].exemplar.id)
              } else {

                console.log('PROPERTY_VALUES')
                console.log(result.data()[0]);
                if (result.data()[0] && result.data()[0].PROPERTY_VALUES) {
                  this.items[index].exemplar = this.getObjValue(result.data()[0].PROPERTY_VALUES)
                  this.items[index].exemplar.id = this.items[index].id_exemplar;
                  this.items[index].exemplar.name = this.getSimpleExemplarName(index);
                  resolve(this.items[index].exemplar.id)
                }
                resolve();
              }
            }
        )
      }).then(async (exemplarId) => {
        if (exemplarId) {
          await new Promise((resolve) => {
            BX24.callMethod(
                "mindentity.item.get",
                {
                  ENTITY: "product_characteristic",
                  FILTER: {
                    NAME: exemplarId
                  }
                },
                async (result) => {
                  if (result.error()) {
                    alert("Ошибка поиска характеристик экземпляра")
                    console.error(result.error());
                    resolve();
                  } else {
                    this.items[index].exemplar.items = [];
                    console.log('EXEMPLAR');
                    console.log(result.data());
                    if (Array.isArray(result.data())) {
                      for (let item of result.data()) {
                        this.items[index].exemplar.items.push({
                          comment: item.PROPERTY_VALUES.comment,
                          date_confirm: this.formatDateForDataPicker(item.PROPERTY_VALUES.date_confirm),
                          date_reserv: this.formatDateForDataPicker(item.PROPERTY_VALUES.date_reserv),
                          date_reserv_out: this.formatDateForDataPicker(item.PROPERTY_VALUES.date_reserv_out),
                          id: item.ID,
                          id_attr: item.PROPERTY_VALUES.id_attr,
                          id_element: item.PROPERTY_VALUES.id_element,
                          id_exemplar: item.PROPERTY_VALUES.id_exemplar,
                          id_material: item.PROPERTY_VALUES.id_material,
                          id_shipper: item.PROPERTY_VALUES.id_shipper,
                          quantity: item.PROPERTY_VALUES.quantity,
                          value: item.PROPERTY_VALUES.value,
                        })
                      }
                      resolve();
                    }
                  }
                }
            );
          })
        }
      })
    },

    async searchSale(index) {
      return await new Promise((resolve) => {
        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_sales",
              FILTER: {
                ID: this.items[index].sale_id,
              }
            },
            (result) => {
              if (result.error()) {
                alert("Ошибка поиска акций")
                console.error(result.error());
                resolve(false)
              } else {

                if (result.data()[0] && result.data()[0].ID && result.data()[0].NAME) {
                  this.items[index].sale_id = {
                    value: '',
                    label: '',
                  }
                  this.items[index].sale_id.value = result.data()[0].ID;
                  this.items[index].sale_id.label = result.data()[0].NAME;
                  resolve(this.items[index].sale_id.value)
                }
                resolve();
              }
            }
        )
      })
    },


    formatDateForBitrix(dateString) {
      let date = new Date(dateString);
      return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    },


    formatDateForDataPicker(dateString) {
      if (!dateString)
        return dateString;
      let parts = dateString.split('.');

      if (parts.length < 3)
        return '';

      return new Date(parts[2], parts[1] - 1, parts[0]);
      //
      // try {
      //   return date.toISOString();
      // } catch (error) {
      //   return '';
      // }

    },


    async synchronizeDataInDealAndInfoblock() {


      const _this = this;

      let crmIds = [];
      let indexes = [];
      let promises = [];
      let productsForCreate = [];
      for (let infoBlock of this.items) {
        crmIds.push(infoBlock.crm.ID);
      }
      await new Promise((resolve) => {
        BX24.callMethod(
            "crm.deal.productrows.get",
            {
              id: this.dealId,
              start: 0,
            },
            (result) => {
              if (result.error()) {
                alert("Ошибка Получения товаров в сделке")
                console.error(result.error());
              } else {
                if (Array.isArray(result.data())) {
                  for (let product of result.data()) {
                    //console.log(product.PRODUCT_ID.toString());
                    if (crmIds.includes(product.PRODUCT_ID.toString())) {
                      //console.log('Не создаем ' + product.PRODUCT_ID);
                    } else {
                      productsForCreate.push(this.getObjValue(product));
                    }
                  }
                  resolve(productsForCreate)
                }
              }
            }
        );
      }).then(async (productIds) => {
        for (let product of productIds) {
          let p2 = await new Promise(async (resolve) => {
            this.items.push(this.getObjValue(this.item_template));
            let index = ((this.items.length - 1) >= 0) ? this.items.length - 1 : 0;
            this.items[index].crm = {};
            this.items[index].crm.id = product.PRODUCT_ID;
            this.items[index].price = product.PRICE_BRUTTO;
            this.items[index].sale = product.DISCOUNT_RATE;
            this.items[index].quantity = product.QUANTITY;
            this.items[index].searchProdId = product.PRODUCT_ID;
            await this.searchProdInCrmAndPasteToItems(index, false);
            indexes.push(index);
            resolve(index);
          });

          promises.push(p2);
        }

        await Promise.all(promises).then(async () => {
          for (let index of indexes) {
            return await new Promise((resolve, reject) => {
              let item = this.getObjValue(this.items[index]);
              let updateObj = {
                ENTITY: "product_items",
                NAME: this.dealId,
                PROPERTY_VALUES: {
                  id_order: this.dealId,
                  id_product: item.id_product.value,
                  price: item.price,
                  nds: this.ndsPercent,
                  nds_include: this.ndsInPrice ? 1 : 0,
                  sale_id: item.sale_id,
                  sale: item.sale,
                  price_cat: item.price_cat,
                  quantity: item.quantity,
                }
              };
              BX24.callMethod(
                  "mindentity.item.add",
                  updateObj,
                  (result) => {
                    if (result.error()) {
                      alert("Ошибка сохранения товарных позиций в инфоблоке");
                      console.error(result.error());
                    } else {
                      resolve(result.data())
                    }
                  }
              );
            })
          }
        });


      });
    },

    getMinPrise(index) {
      let item = this.getObjValue(this.items[index]);
      let prices = [];
      if (item.crm) {
        if (item.crm.PRICE) {
          prices.push(item.crm.PRICE * 1);
        }
        if (item.crm.CATEGORY_PRICE && Array.isArray(item.crm.CATEGORY_PRICE)) {
          for (let cat of item.crm.CATEGORY_PRICE) {
            if (cat.price && cat.price > 0) {
              prices.push(cat.price * 1);
            }
          }
        }
      }

      if (prices.length) {
        let min = prices.reduce(function (prev, current) {
          return prev < current ? prev : current;
        });
        this.items[index].min_price = min;
      }
    },

  },
  data: function () {
    return {
      createDealTab: false,

      dragging: false,
      enabled: true,
      currentUser: null,

      testDate: '2024-05-11T14:53:00.000Z',
      selectExemplarIndexItem: null,

      openPopupRemains: false,

      sending: false,
      sendTurboRemains: [],

      sendTurbo: false,
      itemsForDelete: [],
      exemplarForDelete: [],
      characteristicForDelete: [],
      dealId: null,
      ndsPercent: 20,

      items: [],
      item_template: {
        id: 0,
        price: 0,
        sale: 0,
        sale_id: null,
        price_cat: '',
        id_product: null,
        quantity: 1,
        id_exemplar: '',
        exemplar: {
          id: '',
          alter_name: '',
          dlinna: '',
          diametr: '',
          visota: '',
          glubina: '',
          items: [],
          name: '',
        },
        min_price: null,
      },
      exemplars_table_template: {
        id: '',
        id_exemplar: '',
        id_attr: '',
        id_element: '',
        id_material: '',
        id_supplier: '',
        value: '',
        quantity: '',
        date_reservation: '',
        date_success: '',
        date_reservation_off: '',
        comment: '',

      },

      currentExemplarIndex: null,
      openModal: false,
    }
  }
  ,
  mounted() {

    BX24.init(async () => {
      setInterval(() => {
        BX24.fitWindow();
      }, 333)

      let t = BX24.placement.info()
      BX24.callMethod('user.current', {}, (res) => {
        this.currentUser = res.data()
      });


      if (t && t.options && t.options.ID && t.options.ID !== "0") {
        this.dealId = t.options.ID;


        let getProductsPromise = new Promise((resolve) => {
          BX24.callMethod(
              "mindentity.item.get",
              {
                ENTITY: "product_items",
                FILTER: {
                  NAME: this.dealId
                },
                SORT: {SORT: 'ASC'},
                START: 0,
              },
              async (result) => {
                if (result.error()) {
                  alert("Ошибка поиска товарных позиций")
                  console.error(result.error());
                } else {
                  console.log("ITEMs INFO BLOCK", result)
                  if (Array.isArray(result.data())) {
                    for (let item of result.data()) {
                      this.items.push({
                        id: item.ID,
                        id_exemplar: item.PROPERTY_VALUES.id_exemplar,
                        price: item.PROPERTY_VALUES.price * 1,
                        sale: item.PROPERTY_VALUES.sale * 1,
                        sale_id: item.PROPERTY_VALUES.sale_id,
                        price_cat: item.PROPERTY_VALUES.price_cat,
                        searchProdId: item.PROPERTY_VALUES.id_product,
                        id_product: null,
                        quantity: item.PROPERTY_VALUES.quantity * 1,
                        exemplar: {}
                      })
                    }

                    for (let i = 0; i < this.items.length; i++) {
                      if (this.items[i].id_exemplar)
                        await this.searchExemplar(i);
                      if (this.items[i].sale_id)
                        await this.searchSale(i);
                      await this.searchProdInCrmAndPasteToItems(i, false)
                    }
                  }
                }

                if (result.more()) {
                  result.next();
                  // resolve();
                } else {
                  // resolve();
                }
                resolve();
              }
          );
        });


        let getContactPromise = new Promise((resolve) => {
          BX24.callMethod('batch',
              {
                'halt': 0,
                'cmd': {
                  'deal': 'crm.deal.get?id=' + this.dealId,
                  'deal_contacts': 'crm.deal.contact.items.get?id=' + this.dealId,
                  'company': 'crm.company.get?id=$result[deal][COMPANY_ID]',
                  'company_requisite': 'crm.requisite.list?filter[ENTITY_TYPE_ID]=4&filter[ENTITY_ID]=$result[company][ID]',
                  'company_address': 'crm.address.list?filter[ENTITY_TYPE_ID]=8&filter[ENTITY_ID]=$result[company_requisite][0][ID]',
                }
              },
              (response) => {
                response = response.data().result
                console.log('batch res', response);

                if (response.deal_contacts) {
                  this.getContactsAllData(response.deal_contacts)
                }
                if (this.contact_id) {
                  this.getContactsAllData([{CONTACT_ID: this.contact_id}])
                }
                if (response.deal)
                  this.$store.commit('setCurrentDeal', response.deal)
                if (response.company) {
                  let company = response.company;
                  response.company_requisite && response.company_requisite[0] ? company.REQUISITE = response.company_requisite[0] : company.REQUISITE = null;
                  response.company_address && response.company_address[0] ? company.ADDRESES = response.company_address[0] : company.ADDRESES = null;
                  this.$store.commit('setCurrentCompany', company)
                }
                resolve();
              }
          );
        })


        await Promise.all([getProductsPromise, getContactPromise]).then(async () => {
          await this.synchronizeDataInDealAndInfoblock();
        }).then(() => {
          for (let i = 0; i < this.items.length; i++) {
            this.getMinPrise(i);
          }

          this.getTurboInfo();
        })

      } else if (t && t.options && t.options.ID && t.options.ID === "0") {
        this.createDealTab = true;
      }

    });
  }
}

</script>

<style>
body {
  background-color: #eef2f4 !important;
}

.table-scroll-body {
  overflow: auto;
  min-height: 477px;
  max-height: 573px;
}

.items-list * {
  font-size: 14px !important;
}

.table-scroll {
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 500px;
}

.table-scroll .spinner-border {
  width: 21px;
  height: 21px;
}

.description-product-val {
  display: none;
  position: absolute;
  background-color: white;
  z-index: 9999999;
  box-shadow: 1px 1px 10px -3px black;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 600px;
  top: 100%;
  left: 0px;
}

.description-product:hover .description-product-val {
  display: block;
}

.description-product-val p {
  font-size: 15px !important;
  font-weight: bold;
  margin-bottom: 5px;
}

.description-product {
  position: relative;
  margin-right: 5px;
}

.descr-price {
  font-size: 15px !important;
}

.descr-price b {
  font-size: 17px !important;
}

.table-scroll td input.form-control {
  padding-top: 10px;
  padding-bottom: 10px;
}

.items-list table td {
  vertical-align: middle;
}

.exemplar-prop {
  font-size: 10px !important;
}

.table-remains {
  overflow: auto;
}

.table-remains td {
  min-width: 114px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.not-draggable {
  cursor: no-drop;
}

tr[draggable="true"] * {
  cursor: ns-resize !Important;
}
</style>