<template>
  <div class="backdrop" @click="close">
    <div class="popup" @click.stop>
      <h1>Экземпляр</h1>

      <div v-if="userId === 176">
        <button @click="test()">Test</button>
      </div>
      <div v-if="userId === 176">
        <button @click="update()">Update</button>
      </div>


      <div class="row">
        <div class="col-12 mb-3">
          <div class="row">
            <div class="col-md-7">
              <p>Название</p>
              <input type="text" class="form-control" v-model="element.alter_name">
            </div>
            <div class="col-md-2">
              <p>Кол-во</p>
              <input type="text" disabled class="form-control" :value="item.quantity">
            </div>
            <div class="col-md-3">
              <p>Цена</p>
              <input type="text" disabled class="form-control" :value="(item.price*1).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                })">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="sizes-box" style="background-color: whitesmoke;">
            <h3>Стандартный размер</h3>
            <p>Длина</p>
            <input type="number" class="form-control mb-2" disabled
                   :value="item.crm && item.crm.PROPERTY_172 && item.crm.PROPERTY_172.value ? item.crm.PROPERTY_172.value : '-'">
            <p>Высота</p>
            <input type="number" class="form-control mb-2" disabled
                   :value="item.crm && item.crm.PROPERTY_173 && item.crm.PROPERTY_173.value ? item.crm.PROPERTY_173.value : '-'">
            <p>Диаметр</p>
            <input type="number" class="form-control mb-2" disabled
                   :value="item.crm && item.crm.PROPERTY_174 && item.crm.PROPERTY_174.value ? item.crm.PROPERTY_174.value : '-'">
            <p>Глубина</p>
            <input type="number" class="form-control mb-2" disabled
                   :value="item.crm && item.crm.PROPERTY_175 && item.crm.PROPERTY_175.value ? item.crm.PROPERTY_175.value : '-'">
          </div>
        </div>
        <div class="col-md-6">
          <div class="sizes-box">
            <h3>Текущий размер</h3>
            <p>Длина</p>
            <input type="number" class="form-control mb-2" v-model="element.dlinna">
            <p>Высота</p>
            <input type="number" class="form-control mb-2" v-model="element.visota">
            <p>Диаметр</p>
            <input type="number" class="form-control mb-2" v-model="element.diametr">
            <p>Глубина</p>
            <input type="number" class="form-control mb-2" v-model="element.glubina">
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="d-flex">
            <button class="btn btn-sm btn-outline-success" @click="element.items.push({...item_template})">
              Добавить
            </button>
            <div class="a" style="margin-left: auto">
              <button style="margin-right: 5px;" class="btn btn-success" @click="confirm">Ok</button>

              <button style="margin-right: 5px;" class="btn btn-secondary" @click="close">Отмена</button>

              <button class="btn btn-danger" @click="deleteExem">Удалить</button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="table-scroll" style="padding-bottom: 95px;">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th style="min-width: 373px;">Атрибут</th>
                <th style="min-width: 373px;">Элемент атрибута</th>
                <th style="min-width: 210px;">Значение</th>
                <th style="min-width: 373px;">Материал</th>
                <th style="min-width: 150px;">Кол-во</th>
                <th style="min-width: 373px;">Поставщик</th>
                <th style="min-width: 210px;">Резерв до даты</th>
                <th style="min-width: 210px;">Дата подтверждения</th>
                <th style="min-width: 210px;">Дата снятия резерва</th>
                <th style="min-width: 270px;">Комментарий</th>
                <th>#</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in element.items" :key="index">
                <td>
                  <Multiselect v-model="item.id_attr" :options="$store.state.attributesField"
                               @select="onSelectAttrField"
                               :valueProp="'ID'"
                               :trackBy="'NAME'" :label="'NAME'" :searchable="true"/>
                </td>
                <td>
                  <Multiselect v-if="item.id_attr" v-model="item.id_element"
                               @select="onSelectAttrField"
                               :options="$store.state.attributesElementField.filter((elem)=>{return elem.PROPERTY_VALUES.id_attribute*1 === item.id_attr*1})"
                               :valueProp="'ID'"
                               :trackBy="'NAME'" :label="'NAME'" :searchable="true"/>
                  <span v-else>Выберите атрибут</span>
                </td>
                <td>
                  <input type="text" class="form-control m-0" v-model="item.value">
                </td>
                <td>
                  <Multiselect v-model="item.id_material" :options="$store.state.materialsField"
                               @select="onSelectAttrField"
                               :valueProp="'ID'"
                               :trackBy="'NAME'" :label="'NAME'" :searchable="true"/>
                </td>
                <td>
                  <input type="number" class="form-control m-0" v-model="item.quantity" min="0">
                </td>
                <td>
                  <Multiselect v-model="item.id_shipper" :options="$store.state.shippersField"
                               @select="onSelectAttrField"
                               :valueProp="'ID'"
                               :trackBy="'NAME'" :label="'NAME'" :searchable="true"/>
                </td>
                <td>
                  <Datepicker v-model="item.date_reserv" :format="'dd.MM.yyyy'" autoApply
                              locale="ru"
                              :enableTimePicker="false"/>
                </td>
                <td>
                  <Datepicker v-model="item.date_confirm" :format="'dd.MM.yyyy'" autoApply
                              locale="ru"
                              :enableTimePicker="false"/>
                </td>
                <td>
                  <Datepicker v-model="item.date_reserv_out" :format="'dd.MM.yyyy'" autoApply
                              locale="ru"
                              :enableTimePicker="false"/>
                </td>

                <td>
                  <input type="text" class="form-control m-0" v-model="item.comment">
                </td>
                <td>
                  <button class="btn btn-outline-danger" @click="deleteItem(index)">
                    <i class="bi bi-x-lg"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <br>


    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<script>
/* eslint-disable */
import Multiselect from '@vueform/multiselect'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  currentPopupController: null,
  components: {
    Multiselect,
    Datepicker
  },
  props: {
    item: Object,
  },
  data() {
    return {
      isOpen: false,
      element: {
        id: '',
        alter_name: '',
        dlinna: '',
        diametr: '',
        visota: '',
        glubina: '',
        items: [],
        name: '',
      },
      item_template: {
        id_exemplar: '',
        id_attr: '',
        id_element: '',
        value: '',
        id_material: '',
        quantity: '',
        id_shipper: '',
        comment: '',
        date_reserv: '',
        date_reserv_out: '',
        date_confirm: '',
      },

      resultObj: [],
      attributes: [],
      userId: 0,

    };
  },
  mounted() {
    this.attributeInit();

    console.log(this.item);
    BX24.callMethod('user.current', {}, res => {
      console.log(res.data().ID);
      if (res.data().ID * 1 === 176) {
        this.userId = 176;
        this.findAttributesElements();
      }
    });

    this.element = {...this.item.exemplar}
    if (!this.element.items)
      this.element.items = []

    document.addEventListener("keydown", this.handleKeydown);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
  },

  methods: {
    searchAttribute: async function (query) {
      let bx24response = new Promise((resolve, reject) => {
        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_attribute",
              FILTER: {
                "%NAME%": query
              }
            },
            (result) => {
              if (result.error()) {
                alert('Ошибка поиска атрибутов');
                console.error('Ошибка поиска атрибутов', result.error());
              } else {
                resolve(result.data())
              }
            }
        );
      })
      let attributes = [];
      await bx24response.then(result => {
        attributes = result;
      });
      return attributes.map(item => {
        return {
          label: item.NAME,
          value: item.ID,
        }
      });
    },
    searchAttributeElement: async function (query) {
      let bx24response = new Promise((resolve, reject) => {
        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_elemnents_attribute",
              FILTER: {
                "PROPERTY": query
              }
            },
            (result) => {
              if (result.error()) {
                alert('Ошибка поиска атрибутов');
                console.error('Ошибка поиска атрибутов', result.error());
              } else {
                resolve(result.data())
              }
            }
        );
      })
      let attributes = [];
      await bx24response.then(result => {
        attributes = result;
      });
      return attributes.map(item => {
        return {
          label: item.NAME,
          value: item.ID,
        }
      });
    },
    searchMaterials: async function (query) {
      let bx24response = new Promise((resolve, reject) => {
        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_material",
              FILTER: {
                "%NAME%": query
              }
            },
            (result) => {
              if (result.error()) {
                alert('Ошибка поиска материалов');
                console.error('Ошибка поиска материалов', result.error());
              } else {
                resolve(result.data())
              }
            }
        );
      })
      let materials = [];
      await bx24response.then(result => {
        materials = result;
      });
      return materials.map(item => {
        return {
          label: item.NAME,
          value: item.ID,
        }
      });
    },
    searchShippers: async function (query) {
      let bx24response = new Promise((resolve, reject) => {
        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_shipper",
              FILTER: {
                "%NAME%": query
              }
            },
            (result) => {
              if (result.error()) {
                alert('Ошибка поиска shippers');
                console.error('Ошибка поиска shippers', result.error());
              } else {
                resolve(result.data())
              }
            }
        );
      })
      let shippers = [];
      await bx24response.then(result => {
        shippers = result;
      });
      return shippers.map(item => {
        return {
          label: item.NAME,
          value: item.ID,
        }
      });
    },

    async deleteItem(index) {

      if (this.element.items[index].id) {
        this.$emit("deleteCharacteristic", this.element.items[index].id);
      }
      this.element.items.splice(index, 1)
    },
    handleKeydown(e) {
      if (this.isOpen && e.key === "Escape") {
        this.close();
      }
    },

    deleteExem() {
      console.log('deleteWindow');
      this.$emit("deleteWindow", this.element)
      this.isOpen = false;
    },

    confirm() {
      console.log('confirmChild');
      this.$emit("confirmWindow", this.element)
      this.isOpen = false;
    },

    close() {
      this.$emit("closeWindow")
      console.log('closeChild');
      this.isOpen = false;
    },


    onSelectAttrField(value, option) {
      console.log('onSelect');
      console.log(option);
      this.formatAlterName();
    },


    formatAlterName() {
      // Если заполнено «Текущий размер» в название ТМЦ выглядит:
      // после название ставиться «запятая», пишется нестандарт и вписываются внесенные размеры.
      this.element.alter_name = '';
      let size = {
        dlinna: '',
        visota: '',
        glubina: '',
        diametr: '',
      };
      this.element.alter_name = this.element.name;

      size.dlinna = this.element.dlinna ? '*' + this.element.dlinna : '';
      size.visota = this.element.visota ? '*' + this.element.visota : '';
      size.glubina = this.element.glubina ? '*' + this.element.glubina : '';
      size.diametr = this.element.diametr ? '*' + this.element.diametr : '';

      const values = Object.values(size);
      if (!(values.every(value => !value))) {
        this.element.alter_name += `, нестандарт ${size.dlinna}${size.visota}${size.diametr}${size.glubina}`.replace(/^\*|(\*)/, '');
      }

      //
      let attributeSubStr = '';
      let multiSelectArr = [
        {
          options: this.$store.state.attributesField,
          key: 'id_attr',
        },
        {
          options: this.$store.state.attributesElementField,
          key: 'id_element',
        },
        {
          options: this.$store.state.materialsField,
          key: 'id_material',
        },
        {
          options: this.$store.state.shippersField,
          key: 'id_shipper',
        },

      ];
      let valueArr = [
        'value',
        'quantity',
        'comment',
      ];
      let dateTimeArr = [
        'date_reserv',
        'date_reserv_out',
        'date_confirm',
      ]
      if (this.element.items && this.element.items[0]) {
        for (let item of this.element.items) {

          let arrForStr = [];
          for (let prop of multiSelectArr) {
            if (item[prop.key]) {
              console.log(prop.options[0]);
              let option = prop.options.filter((elem) => {
                return elem.ID * 1 === item[prop.key] * 1
              });
              if (option && option[0] && option[0].NAME) {
                arrForStr.push(option[0].NAME);
              }
            }
          }
          for (let prop of valueArr) {
            if (item[prop]) {
              arrForStr.push(item[prop]);
            }
          }
          for (let prop of dateTimeArr) {
            if (item[prop]) {
              let date = new Date(item[prop]);
              arrForStr.push(date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear());
            }
          }
          if (arrForStr.length !== 0) {
            let itemStr = '(';
            let pos = 0;
            for (let attr of arrForStr) {
              if (pos === 0) {
                itemStr += attr;
              } else {
                itemStr += '/' + attr;
              }
              pos++;
            }
            itemStr += ')';
            attributeSubStr += itemStr;
          }
        }
      }
      if (attributeSubStr) {
        this.element.alter_name += '(' + attributeSubStr + ')'
      }

    },

    findAttributesElements() {

      let attrIds = [
        "133473",
        "133311",
        "133470",
        "133323",
        "133373",
        "133308",
        "133386",
        "133484",
        "133313",
        "133633",
        "133365",
        "133316",
        "133350",
        "133309",
        "133375",
        "133461",
      ]


      BX24.callMethod(
          "mindentity.item.get",
          {
            ENTITY: "product_elemnents_attribute",
          },
          (result) => {
            if (result.error()) {
              console.log('error mindentity.item.get', result.error());
              alert("Ошибка в консоле");
            } else {
              for (let elem of result.data()) {
                this.findElementInAttributeObject(elem);
              }
              if (result.more())
                result.next();
            }
          }
      );
    },


    attributeInit(){








      let oldAttributes = [
        {
          name: "Цвет наконечника ножек",
          id: '133473',
          props: ["С отделкой под золото"]//+
        },
        {
          name: "Цвет каркаса",
          id: '133311',
          props: [
            "Черный муар", "Цвет RAL",
            "Тёмный Орех", "Сталь под прозрачным лаком",
            "С отделкой под медь(Copper Desert)", "С отделкой под золото(Super Gold PE)(порошок)",
            "Натуральный Дуб", "Выбеленный Дуб"]
        },
        {
          name: "Утяжка",
          id: '133470',
          props: ["Стандарт", "с пуговицей", "Каретная с пуговицей", "Каретная", "без утяжки", "без пуговиц"]
        },
        {name: "Упаковка", id: '133323', props: ["жесткая тара", "картон", "пленка", "пленка+картон"]},
        {
          name: "Тонировка с ПАТИНОЙ",
          id: '133373',
          props: ["Темный орех+черная патина", "Темный орех+золотая патина", "Темный орех+белая патина", "Мурано", "Выбеленный 2+патина орех", "Выбеленный 2+золотая патина", "Выбеленный 1+ черная патина", "RAL 8019+белая патина"]
        },
        {
          name: "Тонировка",
          id: '133308',
          props: ["черный венге без красноты", "темный орех", "светлый орех", "палисандр", "натуральный ясень", "натуральный дуб", "натуральный бук", "максимально выбеленный", "коньяк", "зеленый дуб", "выбеленный дуб 2", "выбеленный дуб 1", "выбеленный бук 2", "выбеленный бук 1", "выбеленный ясень 2", "выбеленный ясень 1", "водный венге", "вишня", "венге", "подбор цвета по образцу заказчика", "по таблице RAL (эмаль)", "морилка под RAL ( тонировка считается отдельно)"]
        },
        {
          name: "Срок изготовления",
          id: '133386',
          props: ["согласно договору", "под заказ", "на данный момент есть в наличии", "50 рабочих дней", "45 рабочих дней", "25-30 рабочих дней", "25 рабочих дней", "20 рабочих дней", "15 рабочих дней"]
        },
        {
          name: "Покрытие специальными лаками",
          id: '133484',
          props: ["Самогрунтующийся лак", "Покрытие ЭЛАТЕКС двухсторонее", "Корабельный лак"]
        },
        {
          name: "Ножки",
          id: '133313',
          props: ["как у стола Токио", "квадратные хром", "круглые хром", "массив бука", "металл"]
        },
        {name: "Молдинг", id: '133633', props: ["стандарт OLD GOLD", "молдинг", "без молдинга"]},
        {name: "ЛДСП", id: '133388', props: ["EGGER", "Венге подлинный"]},
        {
          name: "Кромка",
          id: '133316',
          props: ["2мм ПВХ", "Массив дуба для столешниц тип Конверт  выпуклая", "Массив дуба для столешниц тип Конверт  вогнутая", "пластик", "под цвет пластика", "ФИГУРНАЯ МДФ"]
        },
        {name: "Кольцо", id: '133350', props: ["без кольца", "латунь", "хром"]},
        {name: "Обивка", id: '133309', props: ["Ткань", "Кожзам", "Натуральная кожа"]},
        {
          name: "Сборка",
          id: '133375',
          props: ["Разобранные", "Разобранный", "Разобранное", "барного стула", "К подстольям прикрутить столешницы", "к стулу прикрутить сиденье", "подстольев", "собранные","кресел", "собранный", "собранное", "стульев"],
        },
        {
          name: "Покрытие",
          id: '133387',
          props: [
            "двойное покрытие лаком",
            "ЛДСП",
            "Пластик",
            "пластик EGGER",],
        },


      ];

      let index = 0;
      for (let attr of oldAttributes) {
        this.attributes.push({
          name: attr.name,
          id: attr.id,
          props: [],
        })

        for (let prop of attr.props) {
          this.attributes[index].props.push({
            id: null,
            name: this.formatString(prop),
          })
        }
        index++;
      }


    },

    findElementInAttributeObject(elem) {



      for (let attribute of this.attributes) {
        if (elem.PROPERTY_VALUES.id_attr * 1 === attribute.id * 1) {

          let attrProp = attribute.props.find((element) => this.formatString(elem.NAME) === element.name);
          if (attrProp) {

            let found = this.resultObj.find((element) => element.attributeId === attribute.id);

            if (found) {
              found.props.push({
                name: elem.NAME,
                id: elem.ID,
              })
              attrProp.id = elem.ID;

            } else {
              this.resultObj.push({
                attributeName: attribute.name,
                attributeId: attribute.id,
                props: [{
                  name: elem.NAME,
                  id: elem.ID,
                }]
              })
              attrProp.id = elem.ID;
            }


          }
        }
      }
    },

    formatString(str) {
      return str.trim().replace(/\s{2,}/g, ' ').toLowerCase();
    },

    test() {

        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_elemnents_attribute",
              FILTER: {
                PROPERTY_is_visible_in_product_app: "YES",
              },
            },
            (result) => {
              if (result.error()) {
                console.log('error mindentity.item.get', result.error());
                alert("Ошибка в консоле");
              } else {
                console.log('result.data()');
                console.log(result.data());
                this.$store.commit('setAttributesElementField', result.data())

                if (result.more())
                  result.next();
              }
            }
        );


      // console.log(this.resultObj);
      // console.log(this.attributes);
    },
    update(){
      // let attrElemIds = [
      //   "134244",
      //   "185197",
      //   "185198",
      //   "133820",
      //   "138631",
      //   "133832",
      //   "134144",
      //   "138700",
      //   "138643",
      //   "133819",
      //   "134048",
      //   "138691",
      //   "137272",
      //   "138651",
      //   "138650",
      //   "138648",
      //   "135335",
      //   "138567",
      //   "134298",
      //   "138563",
      //   "138696",
      //   "138346",
      //   "138345",
      //   "138344",
      //   "134046",
      //   "138342",
      //   "138343",
      //   "138341",
      //   "185243",
      //   "136569",
      //   "133814",
      //   "138587",
      //   "134164",
      //   "138703",
      //   "134170",
      //   "133821",
      //   "138710",
      //   "133999",
      //   "134138",
      //   "138449",
      //   "136538",
      //   "137106",
      //   "134233",
      //   "185241",
      //   "185240",
      //   "134044",
      //   "138585",
      //   "133822",
      //   "136306",
      //   "185242",
      //   "136361",
      //   "137842",
      //   "134589",
      //   "136218",
      //   "137553",
      //   "185239",
      //   "185238",
      //   "185237",
      //   "134155",
      //   "134130",
      //   "134245",
      //   "138709",
      //   "134163",
      //   "136646",
      //   "134401",
      //   "134386",
      //   "134129",
      //   "138687",
      //   "138695",
      //   "138647",
      //   "138684",
      //   "134154",
      //   "185245",
      //   "134126",
      //   "138353",
      //   "138354",
      //   "134221",
      //   "138258",
      //   "137965",
      //   "138649",
      //   "134070",
      //   "133989",
      //   "133813",
      //   "185248",
      //   "134041",
      //   "134123",
      //   "134134",
      //   "134924",
      //   "134682",
      //   "134413",
      //   "134835",
      //   "134302",
      //   "134147",
      //   "134185",
      //   "185247",
      //   "134394",
      //   "134558",//Кресел
      // ]

      let attrElemIds = [
          "133473",
              "133311",
              "133470",
              "133323",
              "133373",
              "133308",
              "133386",
              "133484",
              "133313",
              "133633",
              "133365",
              "133316",
              "133350",
              "133309",
              "133375",
              "133461",
              "133387",
          ]

      for(let elemId of attrElemIds){
        let updateObj = {
          ENTITY: "product_attribute",
          PROPERTY_VALUES: {
            is_visible_in_product_app: "YES",
          },
          ID:elemId,
        };

        BX24.callMethod(
            "mindentity.item.update",
            updateObj,
            (result) => {
              if (result.error()) {
                alert("Ошибка сохранения");
                console.error(result.error());
              } else {
                console.log('success');
                console.log(updateObj);
              }
            }
        );


      }

    }
  },
  watch: {

    'element.dlinna'(newValue, oldValue) {
      this.formatAlterName();
    },
    'element.visota'(newValue, oldValue) {
      this.formatAlterName();
    },
    'element.diametr'(newValue, oldValue) {
      this.formatAlterName();
    },
    'element.glubina'(newValue, oldValue) {
      this.formatAlterName();
    },

    'element.items': {
      handler(newValue, oldValue) {
        this.formatAlterName();
      },
      deep: true,
    }
  },

}
;
</script>

<style>
.sizes-box {
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 15px;
}

.popup {
  width: 93%;
  top: 50px;
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  z-index: 101;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
  min-height: 100px;
  max-height: 90vh;
}

.popup h1 {
  text-align: center;
  margin: 0;
}

.popup p {
  margin: 0;
}


.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.footer {
  text-align: right;
}
</style>
