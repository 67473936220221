import {createWebHashHistory, createRouter} from "vue-router";
//import HomeView from "@/views/HomeView.vue";
import PlacementDealTabView from "@/views/PlacementDealTabView.vue";
import AboutView from "@/views/AboutView.vue";
import InstallView from "@/views/InstallView.vue";

const routes = [
    {
        path: "/",
        name: "PlacementDealTabView",
        component: PlacementDealTabView,
    },
    {
        path: "/tab-detail",
        name: "PlacementDealTabView",
        component: PlacementDealTabView,
    },
    {
        path: "/about",
        name: "AboutView",
        component: AboutView,
    },
    {
        path: "/install",
        name: "InstallView",
        component: InstallView,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;