<template>
  <div class="backdrop" @click="close">
    <div class="popup" @click.stop>

      <template v-if="item.crm.PREVIEW_PICTURE && item.crm.PREVIEW_PICTURE.showUrl">
        <img :src="'https://crm.restoracia.ru'+item.crm.PREVIEW_PICTURE.showUrl"
             alt="img"
             style="width:100%; max-width: 100px;max-height: 100px;">
      </template>
      <div class="row">
        <div class="col-8">
          <p>
            <a style="color: black;"
               :href="'https://crm.restoracia.ru/crm/catalog/14/product/'+item.crm.ID+'/'"
               target="_blank">{{ item.crm.NAME }}</a>
          </p>
        </div>
        <div v-if="item.turboRemains && item.turboRemains.Invoices" class="col-4">
          <p>По счетам: {{ calculateColumnsSum(item.turboRemains.Invoices, 'Sell') }}</p>
          <p>По оплаченным счетам: {{ calculateColumnsSum(item.turboRemains.Invoices, 'Paid') }}</p>
        </div>

      </div>

      <hr>
      <div class="row">
        <div class="col-6" v-if="item.turboRemains">
          <!--                    <div>-->
          <!--                        В пути: <b>{{ item.turboRemains.Way ? item.turboRemains.Way : "-"}}</b>-->
          <!--                    </div>-->
          <!--                    <div>-->
          <!--                        В производстве: <b>{{ item.turboRemains.Fab ? item.turboRemains.Fab: "-" }}</b>-->
          <!--                    </div>-->
          <!--                    <div >-->
          <!--                        На складе: <b>{{ item.turboRemains.Quantity ? item.turboRemains.Quantity : "-" }}</b>-->
          <!--                    </div>-->
          <!--                    <div >-->
          <!--                        Зарезервировано: <b>{{ item.turboRemains.Reserve ? item.turboRemains.Reserve : "-" }}</b>-->
          <!--                    </div>-->
          <!--                    <div>-->
          <!--                        Доступно: <b>{{ item.turboRemains.Aviable ? item.turboRemains.Aviable : "-" }}</b>-->
          <!--                    </div>-->

          <div>
            Остаток готовых изделий:
            <b>{{ item.crm.PROPERTY_82 && item.crm.PROPERTY_82.value ? item.crm.PROPERTY_82.value : "-" }}</b>
          </div>
          <div>
            <!--По счетам-->
            Выписано счетов: <b>{{ calculateColumnsSum(item.turboRemains.Invoices, 'Sell') }}</b>
          </div>
          <div>
            <!--К продаже (готовые изделия)-->
            Доступно готовых изделий к продаже:
            <b>{{ item.crm.PROPERTY_77 && item.crm.PROPERTY_77.value ? item.crm.PROPERTY_77.value : "-" }}</b>
          </div>
        </div>
        <div class="col-6">
          <div>
            <!--  Доступно (заготовки) -->
            Наименьший остаток заготовок:
            <b>{{ item.crm.PROPERTY_83 && item.crm.PROPERTY_83.value ? item.crm.PROPERTY_83.value : "-" }}</b>
          </div>
          <div>
            <!-- По оплаченным счетам -->
            Выписано счетов с учетом переданных в участок: <b>{{
              calculateColumnsSum(item.turboRemains.Invoices, 'Paid')
            }}</b>
          </div>
          <div>
            <!-- Доступно (заготовки) -->
            Доступно заготовок к продаже:<b>{{ calculateSome() !== null ? calculateSome() : "-" }}</b>
          </div>
          <!--                    <div>-->
          <!--                        Остаток: <b>{{ item.crm.PROPERTY_82 && item.crm.PROPERTY_82.value ? item.crm.PROPERTY_82.value : "-" }}</b>-->
          <!--                    </div>-->
          <!--                    <div>-->
          <!--                        Доступно (заготовки): <b>{{ item.crm.PROPERTY_83 && item.crm.PROPERTY_83.value ? item.crm.PROPERTY_83.value : "-" }}</b>-->
          <!--                    </div>-->
          <!--                    <div>-->
          <!--                        К продаже (готовые изделия): <b>{{ item.crm.PROPERTY_77 && item.crm.PROPERTY_77.value ? item.crm.PROPERTY_77.value : "-" }}</b>-->
          <!--                    </div>-->
        </div>
      </div>

      <hr>
      <div class="row">
        <div class="col-12">
          <div>
            Цена актуальна на дату:
            <b>{{ item.crm.PROPERTY_84 && item.crm.PROPERTY_84.value ? item.crm.PROPERTY_84.value : "-" }}</b> <br>
          </div>
          {{
            item.crm.PROPERTY_72 && item.crm.PROPERTY_72.value ? item.crm.PROPERTY_72.value : '-'
          }}
        </div>
      </div>


      <div v-if="item.turboRemains" class="mb-2">
        <div class="table-remains">
          <table class="table table-bordered"
                 v-if="item.turboRemains && item.turboRemains.Divisions">
            <thead>
            <tr>
              <th v-for="(remain, idx) in item.turboRemains.Divisions" :key="idx">
                {{ remain.Division }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td v-for="(remain, idx) in item.turboRemains.Divisions" :key="idx">
                {{ remain.Quantity }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <table class="table table-bordered"
               v-if="item.turboRemains && item.turboRemains.Semiproducts">
          <thead>
          <tr>
            <th> Название</th>
            <th> Код</th>
            <th> Доступно(заготовки)</th>
            <th> Ост.заготовок</th>
            <th> Продано</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(invoice, idx) in item.turboRemains.Semiproducts" :key="idx">
            <td>
              {{ invoice.Name }}
            </td>
            <td>
              {{ invoice.Semiproduct }}
            </td>
            <td>
              {{ invoice.Available }}
            </td>
            <td>
              {{ invoice.Quantity }}
            </td>
            <td>
              {{ invoice.Reserve }}
            </td>
          </tr>
          </tbody>
        </table>

        <div v-if="item.turboRemains.Invoices" class="row mb-4">
          <p>Фильтр по ФИО менеджера</p>
          <div class="col-10">
            <Multiselect v-model="filteredNames"
                         :options="getUniqManagerNames()"
                         @clear="filterByName()"
                         mode="tags"
                         :valueProp="'Manager'"
                         :trackBy="'Manager'" :label="'Manager'" :searchable="true"/>
          </div>
          <div class="col-2">
            <button class="btn btn-sm btn-outline-success" @click="filterByName()">Поиск по фильтру</button>
          </div>
        </div>


        <div class="table-remains">
          <table class="table table-bordered sortable"
                 v-if="item.turboRemains && item.turboRemains.Invoices">
            <thead>
            <tr>

              <th> Заказ №</th>
              <!--                            <th> Создан</th>-->

              <!--  Поля которые можно сортировать-->
              <th @click="sortInvoicesArrayByField('Manager')">
                Менеджер {{ sortedFields.Manager.mode.view }}
              </th>
              <!--                            <th @click="sortInvoicesArrayByField('Order')">-->
              <!--                                Заказано {{ sortedFields.Order.mode.view }}-->
              <!--                            </th>-->
              <!--                            <th @click="sortInvoicesArrayByField('Reserve')">-->
              <!--                                Зарезервировано {{ sortedFields.Reserve.mode.view }}-->
              <!--                            </th>-->
              <th @click="sortInvoicesArrayByField('Sell')">
                Продано {{ sortedFields.Sell.mode.view }}
              </th>
              <th @click="sortInvoicesArrayByField('Paid')">
                Оплачено {{ sortedFields.Paid.mode.view }}
              </th>
              <!--                            <th @click="sortInvoicesArrayByField('inRoad')">-->
              <!--                                В пути {{ sortedFields.inRoad.mode.view }}-->
              <!--                            </th>-->
              <!--                            <th @click="sortInvoicesArrayByField('Fab')">-->
              <!--                                Fab {{ sortedFields.Fab.mode.view }}-->
              <!--                            </th>-->
              <!--  Поля которые можно сортировать-->
              <th> Статус</th>
            </tr>

            </thead>
            <tbody>
            <tr v-for="(invoice, idx) in item.turboRemains.Invoices" :key="idx">
              <td>
                <template v-if="invoice.Invoice_ID">
                  <a :href="'https://crm.restoracia.ru/crm/deal/details/'+invoice.Invoice_ID+'/'"
                     target="_blank">{{ invoice.Invoice }}</a>
                </template>
                <template v-else>
                  {{ invoice.Invoice }}
                </template>
              </td>
              <!--                            <td>-->
              <!--                                {{ invoice.Invoice_Create }}-->
              <!--                            </td>-->
              <td>
                {{ invoice.Manager }}
              </td>
              <!--                            <td>-->
              <!--                                {{ invoice.Order }}-->
              <!--                            </td>-->
              <!--                            <td>-->
              <!--                                {{ invoice.Reserve }}-->
              <!--                            </td>-->

              <td>
                {{ invoice.Sell }}
              </td>
              <td>
                {{ invoice.Paid }}
              </td>
              <!--                            <td>-->
              <!--                                {{ invoice.inRoad }}-->
              <!--                            </td>-->
              <!--                            <td>-->
              <!--                                {{ invoice.Fab }}-->
              <!--                            </td>-->
              <td>
                {{ decodeStatus(invoice.Invoice_Status) }}
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th> Итого</th>
              <!--                            <th> -</th>-->
              <th> -</th>
              <!--                            <th>-->
              <!--                                <div v-if="item && item.turboRemains && item.turboRemains.Invoices">-->
              <!--                                    {{ calculateColumnsSum(item.turboRemains.Invoices, 'Order') }}-->
              <!--                                </div>-->
              <!--                            </th>-->
              <th>
                <div v-if="item && item.turboRemains && item.turboRemains.Invoices">
                  {{ calculateColumnsSum(item.turboRemains.Invoices, 'Reserve') }}
                </div>
              </th>
              <th>
                <div v-if="item && item.turboRemains && item.turboRemains.Invoices">
                  {{ calculateColumnsSum(item.turboRemains.Invoices, 'Sell') }}
                </div>
              </th>
              <th>
                <div v-if="item && item.turboRemains && item.turboRemains.Invoices">
                  {{ calculateColumnsSum(item.turboRemains.Invoices, 'Paid') }}
                </div>
              </th>
              <!--                            <th>-->
              <!--                                <div v-if="item && item.turboRemains && item.turboRemains.Invoices">-->
              <!--                                    {{ calculateColumnsSum(item.turboRemains.Invoices, 'inRoad') }}-->
              <!--                                </div>-->
              <!--                            </th>-->
              <!--                            <th>-->
              <!--                                <div v-if="item && item.turboRemains && item.turboRemains.Invoices">-->
              <!--                                    {{ calculateColumnsSum(item.turboRemains.Invoices, 'Fab') }}-->
              <!--                                </div>-->
              <!--                            </th>-->
              <th> -</th>
            </tr>
            </tfoot>
          </table>

          <table class="table table-bordered w-25"
                 v-if="item.turboRemains && item.turboRemains.Buy">
            <thead>
            <tr>
              <th> Дата прихода товара</th>
              <th> Количество</th>
            </tr>

            </thead>
            <tbody>
            <tr v-for="(product, idx) in item.turboRemains.Buy" :key="idx">
              <td>{{ product.Date }}</td>
              <td>{{ product.Quantity }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <button class="btn btn-sm btn-outline-secondary" @click="close()">
        Закрыть
      </button>

    </div>
  </div>

</template>

<script>
/* eslint-disable */
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    Multiselect,
  },
  currentPopupController: null,
  props: {
    item: Object,
  },
  data() {
    return {
      isOpen: false,
      openPopupFilter: false,
      sortedFields: {
        Manager: {name: 'Менеджер', mode: ''},
        Order: {name: 'Менеджер', mode: ''},
        Reserve: {name: 'Менеджер', mode: ''},
        Sell: {name: 'Менеджер', mode: ''},
        Paid: {name: 'Менеджер', mode: ''},
        inRoad: {name: 'Менеджер', mode: ''},
        Fab: {name: 'Менеджер', mode: ''},
      },

      sortedModes: {
        asc: {
          name: "asc",
          view: "▲"
        },
        desc: {
          name: "desc",
          view: "▼"
        },
        normal: {
          name: "normal",
          view: "►"
        }
      },
      filteredNames: [],

      allInvoices: [],
    };
  },
  mounted() {

    for (let field of Object.keys(this.sortedFields)) {
      this.sortedFields[field].mode = this.sortedModes.normal;
    }
    if (this.item.turboRemains && this.item.turboRemains.Invoices) {
      for (let invoice of this.item.turboRemains.Invoices) {
        this.allInvoices.push(this.getObjValue(invoice));
      }
    }

    document.addEventListener("keydown", this.handleKeydown);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
  },

  methods: {

    handleKeydown(e) {
      if (this.isOpen && e.key === "Escape") {
        this.close();
      }
    },

    getObjValue(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    close() {
      this.$emit("closeWindow")
      this.isOpen = false;
    },

    decodeStatus(status) {
      return status;
      status = status.toUpperCase().split('-');
      const statusMapping = {
        'Н': 'не',
        'H': 'не',
        'Ч': 'частично',
        'О': 'полностью',
        'O': 'полностью',
        'В': 'полностью',
        'B': 'полностью',
        'С': 'полностью',
        'C': 'полностью',
      };


      let paymentStatus = '';
      let readinessStatus = '';
      let shipmentStatus = '';
      let movementStatus = '';

      if (status[0] && statusMapping[status[0]]) {
        paymentStatus = statusMapping[status[0]] + ' оплачен,';
      }
      if (status[1] && statusMapping[status[1]]) {
        readinessStatus = statusMapping[status[1]] + ' выпущен,';
      }
      if (status[2] && statusMapping[status[2]]) {
        shipmentStatus = statusMapping[status[2]] + ' отгружен,';
      }
      if (status[3] && statusMapping[status[3]]) {
        movementStatus = statusMapping[status[3]] + ' перемещен на склад,';
      }
      return `${paymentStatus}${readinessStatus}${shipmentStatus}${movementStatus}`;
    },

    calculateColumnsSum(array, columnName) {
      let sum = 0;
      for (let item of array) {
        if (item[columnName]) {
          sum += item[columnName] * 1;
        }
      }
      return sum
    },

    calculateSome() {

      if(
          this?.item?.turboRemains?.Semiproducts
          && Array.isArray(this.item.turboRemains.Semiproducts)
          && this?.item?.turboRemains?.Semiproducts[0]?.Semiproduct
      ){

        let min = (this.item.turboRemains.Semiproducts[0].Available * 1);
        this.item.turboRemains.Semiproducts.forEach((elem) => {
          if(elem.Semiproduct && min > (elem.Available)){
            min = (elem.Available);
          }
        });

        return min;
      }
      return null;
    },


    sortInvoicesArrayByField(field) {
      let mode = this.updateMode(field);
      if (mode === 'asc') {
        return this.item.turboRemains.Invoices.sort((a, b) => a[field] - b[field]);
      } else if (mode === 'desc') {
        return this.item.turboRemains.Invoices.sort((a, b) => b[field] - a[field]);
      }
    },
    updateMode(field) {


      let modeName = this.sortedFields[field].mode.name;
      for (let sortedField of Object.keys(this.sortedFields)) {
        this.sortedFields[sortedField].mode = this.sortedModes.normal;
      }
      if (modeName === 'asc') {
        this.sortedFields[field].mode = this.sortedModes.desc;
      } else {
        this.sortedFields[field].mode = this.sortedModes.asc;
      }
      return this.sortedFields[field].mode.name;
    },
    getUniqManagerNames() {
      let names = [];
      let uniqInvoices = [];
      if (this.allInvoices) {
        for (let invoice of this.allInvoices) {
          if (invoice.Manager) {
            if (!names.includes(invoice.Manager)) {
              names.push(invoice.Manager);
              uniqInvoices.push(invoice);
            }
          }
        }
      }
      return uniqInvoices;
    },
    filterByName() {
      if (this.item.turboRemains && this.item.turboRemains.Invoices) {
        this.item.turboRemains.Invoices = [];

        if (this.filteredNames.length) {
          for (let invoice of this.allInvoices) {
            if (invoice.Manager && this.filteredNames.includes(invoice.Manager)) {
              this.item.turboRemains.Invoices.push(this.getObjValue(invoice));
            }
          }
        } else {
          for (let invoice of this.allInvoices) {
            this.item.turboRemains.Invoices.push(this.getObjValue(invoice));
          }
        }
      }
    }

  },
  watch: {},

}
;
</script>

<style>
.table.table-bordered.sortable th {
  cursor: pointer;
}

.sizes-box {
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 15px;
}

.popup {
  width: 93%;
  top: 50px;
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  z-index: 101;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
  min-height: 100px;
  max-height: 90vh;
}

.popup h1 {
  text-align: center;
  margin: 0;
}

.popup p {
  margin: 0;
}


.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.footer {
  text-align: right;
}
</style>
