<template>
    <div class="notifies">
        <template v-for="(notify, index) in $store.state.notifies"  :key="index">
            <div class="alert" role="alert" :class="'alert-'+notify.type" @click="$store.commit('deleteNotify', notify.text)">
                {{ notify.text }}
            </div>
        </template>
    </div>
</template>



<script>
export default {
    name: "NotifiesMessages"
}
</script>

<style>
.notifies {
    position: fixed;
    z-index: 99999;
    right: 15px;
    top: 15px;
    width: 300px;
}

.notifies .alert:before {
    content: '×';
    position: absolute;
    top: -2px;
    right: 5px;
    color: black;
    z-index: 9;
}

.notifies .alert {
    position: relative;
}
</style>
