<template>
    <h5>Install</h5>
    <p>Установите нужные зависимости для приложения</p>
    <button class="btn btn-warning" @click="installPlacement()">install placement</button>
    <button class="btn btn-warning" @click="finishInstall()">Finish install</button>
</template>


<script>
/* eslint-disable */
export default {
    name: "InstallView",
    data: function () {
        return {
            disabled: false,
        }
    },
    methods: {
        installPlacement: function () {
            BX24.callMethod(
                "placement.bind",
                {
                    'PLACEMENT': 'CRM_DEAL_DETAIL_TAB',
                    'HANDLER': 'https://prodapp.restoracia.ru/#/tab-detail',
                    'LANG_ALL': {
                        'ru': {
                            'TITLE': 'Товарные позиции',
                            'DESCRIPTION': 'Товарные позиции',
                        }
                    },
                },
                (result) => {
                    if (result.error()) {
                        alert('Ошибка поиска товаров');
                        console.error('Ошибка поиска товаров', result.error());
                    } else {
                        console.log(result.data())
                    }
                }
            );
        },
        finishInstall: function () {
            this.disabled = true;
            BX24.installFinish();
        },

    },
    mounted() {
        this.handler = location.protocol + '//' + location.host + '/bitrix/placeholder';

        BX24.init(() => {


        });
    }
}
</script>

<style scoped>

</style>