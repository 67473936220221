export const state = {
    timeShowNotify: 3333,
    notifies: [],

    showAddNomenclatureModal: false,

    crmProductSalesTotal: null,
    crmProductSales: [],

    contactUserFields: [],
    companyUserFields: [],
    dealUserFields: [],

    attributesField: [],
    attributesFieldTotal: null,

    attributesElementField: [],
    attributesElementFieldTotal: null,

    shippersField: [],
    shippersFieldTotal: null,

    materialsField: [],
    materialsFieldTotal: null,

    currentDeal: null,
    currentCompany: null,
    currentContacts: [],

    crmUsers: [],
    crmUsersTotal: null,
}
