
/* eslint-disable */
export const getters = {
    // notifyLength(state, getters) {
    //     return state.notifies.length
    // },
    contragentType(state, getters){
        if (state.currentCompany && state.currentCompany.REQUISITE && state.currentCompany.REQUISITE.RQ_INN) {
            return state.currentCompany.REQUISITE.RQ_INN.length > 10 ? 0 : 1
        } // юрик = 1 физик = 0
        return 0;
    },
};
